import React, { useEffect } from 'react';
import { Route, Routes } from 'react-router-dom';
import { ConfigProvider } from 'antd';
import { themeVariables } from './assets/theme';
import { PagesEnum } from './constants';
import { CasesContainer } from './pages/cases';
import { Header } from './components/header/Header';
import { HistoryContainer } from './pages/history/History';
import { WinContainer } from './pages/win/Win';
import { useLootboxStore } from './store';
import { Spinner } from './components/spinner';
import { TelegramUserModel } from './api/user';
import { ModalsContainer } from './components/modals/container';
import { ModalType } from './store/modals';
import 'react-roulette-pro/dist/index.css';
import './assets/scss/antd-rewrite.scss';
import './index.scss';
import {Footer} from "./components/footer";

function App() {
  const { userLoading, getUserInfo, user, userId, telegramCallback, showModal } = useLootboxStore();
  const showTelegramModal = () => {
    showModal(ModalType.Telegram);
  };
  const showNoMoneyModal = () => {
    showModal(ModalType.NoMoney);
  };
  const showDepositFlowModal = () => {
    showModal(ModalType.Deposit);
  };

  const onTelegramAuth = (telegramUser: TelegramUserModel) => {
    try {
      telegramCallback(telegramUser);
    } catch (error) {
      console.log('error', error)
    }
  };

  useEffect(() => {
    getUserInfo();
  }, [userId]);

  useEffect(() => {
    const telegramScript = document.createElement('script');
    telegramScript.async = true;
    telegramScript.src = 'https://telegram.org/js/telegram-widget.js?22';

    document.body.appendChild(telegramScript);
  }, []);

  if (userLoading) {
    return (
      <div
        style={{ height: '100vh', width: '100%', display: 'flex', alignItems: 'center', justifyContent: 'center' }}
      >
        <Spinner />
      </div>
    );
  }

  return (
    <div className="app">
      <ConfigProvider theme={{ token: themeVariables }}>
        <Header handleLogin={onTelegramAuth} showDepositFlowModal={showDepositFlowModal} />
        <Routes>
          <Route
            path={`${PagesEnum.Cases}`}
            element={
              <CasesContainer
                handleOpenLoginModal={showTelegramModal}
                handleOpenWarningDepositModal={showNoMoneyModal}
              />
            }
          />
          <Route path={`${PagesEnum.History}`} element={<HistoryContainer />} />
          <Route path={`${PagesEnum.Win}`} element={<WinContainer />} />
        </Routes>
        <Footer />
        <ModalsContainer handleLogin={onTelegramAuth} />
      </ConfigProvider>
    </div>
  );
}

export default App;
