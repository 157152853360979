import React, { useEffect, useMemo, useState } from 'react';
import { Button, Card, DatePicker, Flex, Table, TableColumnsType } from 'antd';
import classNames from "classnames";
import { useTranslation } from 'react-i18next';
import { Typography } from 'src/components/ant/typography';
import CheckIcon from 'src/assets/images/svg/check-green.svg';
import locale from 'antd/es/date-picker/locale/ru_RU';

import { TagsFilter } from '../../components/tags-filter/TagsFilter';
import { PickerLocale } from 'antd/lib/date-picker/generatePicker';
import { HistoryStatus, HistoryTransactionsItem } from '../../api/history';
import { useLootboxStore } from '../../store';
import { useMediaQuery } from '../../hooks/useMediaQuery';
import styles from './history.module.css';
import { formatFullDateAndTimeRu } from "../../utils/formatters";
import { TFunction } from "i18next";

const { RangePicker } = DatePicker;

export const HistoryContainer = () => {
  const { t } = useTranslation();
  const isSmallMobile = useMediaQuery('(max-width: 575px)');
  const { getHistoryTransactions, history } = useLootboxStore();
  const [filterStatus, setFilterStatus] = useState<HistoryStatus | undefined>(HistoryStatus.All);
  const [filterDate, setFilterDate] = useState<string | null>(null);

  const ruLocale = useMemo<PickerLocale>(
    () => ({
      ...locale,
      lang: {
        ...locale.lang,
        rangePlaceholder: [t('Дата начала'), t('Дата конца')],
      },
    }),
    [t]
  );

  const list = useMemo(() => {
    if (filterStatus === HistoryStatus.All) return history;

    return history.filter(({ status }) => status === filterStatus);
  }, [filterStatus, history.length]);

  const statusFilters = useMemo(() => ([
    {
      title: t('HISTORY_FILTER.STATUS_ALL'),
      value: HistoryStatus.All,
    },
    {
      title: t('HISTORY_FILTER.STATUS_IN_PROGRESS'),
      value: HistoryStatus.Done,
    },
    {
      title: t('HISTORY_FILTER.STATUS_REQUEST'),
      value: HistoryStatus.New,
    },
  ]), [t]);

  useEffect(() => {
    getHistoryTransactions({});
  }, []);

  return (
    <div className={styles.container}>
      <Card bordered className={styles.card} styles={{ body: { padding: 0 } }}>
        <Flex justify="space-between" align="center" className={styles.header}>
          <Typography.Title className={styles.title}>{t('HISTORY_FILTER.HEADER')}</Typography.Title>
          <Typography.Text type="secondary" size="small" className={styles.cleaner}>
            {t('HISTORY_FILTER.CLEAR')}
          </Typography.Text>
        </Flex>

        <Flex justify="space-between" align="center" wrap gap={12}>
          <TagsFilter<HistoryStatus>
            className={styles.filterWrapper}
            title={t('HISTORY_FILTER.BY_STATUS')}
            onClick={setFilterStatus}
            list={statusFilters}
            active={filterStatus}
            isMobile={isSmallMobile}
          />

          <Flex align="center" justify="flex-start">
            <Typography.Text size="middle" className={styles.filterDate} strong>
              {t('HISTORY_FILTER.BY_DATE')}
            </Typography.Text>

            <RangePicker
              locale={ruLocale}
              placeholder={[t('HISTORY_FILTER.DATE_RANGE_START'), t('HISTORY_FILTER.DATE_RANGE_END')]}
              placement="topLeft"
              format="DD.MM.YYYY"
            />
          </Flex>
        </Flex>
      </Card>

      <Table
        className={styles.table}
        columns={columns(isSmallMobile, t)}
        dataSource={list}
        rowKey="id"
        pagination={{ position: ['bottomCenter'] }}
        size={isSmallMobile ? 'small' : 'middle'}
      />
    </div>
  );
};

const columns = (isMobile: boolean, t: TFunction<"translation", undefined>): TableColumnsType<HistoryTransactionsItem> => [
  {
    title: t('HISTORY_TABLE.SUM'),
    dataIndex: 'volume',
    render: (value, record) => (
      <>
        {isMobile ? <div className={styles.mobileDate}>{formatFullDateAndTimeRu(record.created_at)}</div> : null}
        <span className={styles.volume}>{value} USDT</span>
      </>
    ),
  },
  {
    title: t('HISTORY_TABLE.DATE_CREATE'),
    dataIndex: 'created_at',
    align: 'right',
    hidden: isMobile,
    render: (v) => formatFullDateAndTimeRu(v)
  },
  {
    title: t('HISTORY_TABLE.DATE_UPDATE'),
    dataIndex: 'updated_at',
    align: 'right',
    hidden: isMobile,
    render: (v) => formatFullDateAndTimeRu(v)
  },
  {
    title: t('HISTORY_TABLE.STATUS'),
    dataIndex: 'status',
    align: 'right',
    render: (value) => {
      if (value === HistoryStatus.New) {
        return (
          <Button size={'small'} className={styles.button} disabled>
            {t('HISTORY_TABLE.IN_PROGRESS')}
          </Button>
        );
      }
      return (
        <Button size={'small'} className={classNames(styles.button)}>
          <img src={CheckIcon} alt={value} /> {t('HISTORY_TABLE.DONE')}
        </Button>
      );
    },
  },
];
