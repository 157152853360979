import { Languages, supportedLngs } from "../../i18n";

const supportedLanguages = new Set<Languages>(supportedLngs);

export const getSupportedApiLocale = (locale: string): Languages => {
  if (supportedLanguages.has(locale as Languages)) {
    return locale as Languages;
  }

  return Languages.EN;
};
