import api from './index';

export enum GiftTypeEnum {
  Coins = 'COINS',
  Altcoins = 'ALTCOINS',
  Nft = 'NFT',
  Merch = 'MERCH',
}
export enum StatusWin {
  New = 'NEW',
  Requested = 'REQUESTED',
  Completed = 'COMPLETED',
}

export type GiftListItemModel = {
  id: string;
  user_id: string;
  user_name: string;
  telegram: string;
  // external_id: number;
  name: string;
  type: GiftTypeEnum;
  photo_id: string;
  received_at: string;
  status?: StatusWin;
  currency: string;
  price: number;
  value: string;
};

export const getGiftsByUserApi = (user_id: string) =>
  api.get<GiftListItemModel[]>('partner/gifts/list_user_gifts', { params: { user_id } });

export const withdrawGiftApi = (user_id: string, gift_id: string) =>
  api.post('partner/gifts/update_user_gift', { gift_id, status: StatusWin.Requested });
