import {AxiosResponse} from "axios";

export const requestRepeater = async <T>(
  func: () => Promise<AxiosResponse<T>>,
  retries = 5
): Promise<AxiosResponse<T>> => {
  let attempts = 0;

  while (attempts < retries) {
    try {
      const response = await func();
      if (response.status !== 200) {
        throw new Error(`Request failed with status: ${response.status}`);
      }
      return response; // Возвращаем успешный ответ
    } catch (error: any) {
      attempts++;
      console.error(`Attempt ${attempts} failed: ${error.message}`);
      if (attempts >= retries) {
        throw new Error(`All ${retries} attempts failed.`);
      }
      // Можно добавить задержку перед следующим запросом, если нужно
      await new Promise((resolve) => setTimeout(resolve, 1000)); // Задержка 1 секунда
    }
  }

  throw new Error('This should never be reached');
};
