import React from 'react';
import { Button, Card } from 'antd';
//@ts-ignore
import TelegramLoginButton from 'react-telegram-login';
import WalletIcon from 'src/assets/images/svg/wallet.svg';
import WalletGreenIcon from 'src/assets/images/svg/wallet-green.svg';
import { Typography } from 'src/components/ant/typography';
import { useMediaQuery } from 'src/hooks/useMediaQuery';

import styles from './balanceBlock.module.css';
import { TelegramUserModel, UserModel } from '../../../api/user';
import { useTranslation } from 'react-i18next';

const ICON_PROPS = (isSmallMobile: boolean) => ({
  width: isSmallMobile ? 16 : 24,
  height: isSmallMobile ? 16 : 24,
});

type Props = {
  user: UserModel | null;
  handleLogin: (v: TelegramUserModel) => void;
  showDepositFlowModal: () => void;
};

export const BalanceBlock = ({ user, handleLogin, showDepositFlowModal }: Props) => {
  const { t } = useTranslation();
  const isSmallMobile = useMediaQuery('(max-width: 575px)');

  if (user?.id) {
    return (
      <Card bordered className={styles.card} styles={{ body: { padding: '0' } }}>
        <div className={styles.container}>
          {isSmallMobile ? null : <img src={WalletIcon} {...ICON_PROPS(isSmallMobile)} alt="wallet-icon" />}
          <Typography.Title level={3} className={styles.text}>
            <span>{t('BALANCE_BLOCK')}: </span>
            {user?.attempt_balance || 0.0}
            <Typography.Title level={3} type="secondary">
              USDT
            </Typography.Title>
          </Typography.Title>

          <Button size="small" className={styles.button} onClick={showDepositFlowModal}>
            {isSmallMobile ? (
              <img src={WalletGreenIcon} {...ICON_PROPS(isSmallMobile)} alt="wallet-icon" />
            ) : (
              t('BALANCE_TOP_UP')
            )}
          </Button>
        </div>
      </Card>
    );
  }

  return (
    <TelegramLoginButton
      dataOnauth={handleLogin}
      usePic={false}
      buttonSize={'medium'}
      botName={process.env.REACT_APP_BOT_NAME}
    />
  );
};
