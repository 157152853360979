import { create } from 'zustand';
import Cookies from 'js-cookie';
import { getLootboxesApi, GetLootBoxListItem } from '../api/cases';
import {
  getUserInfoApi,
  telegramCallbackApi,
  TelegramUserModel,
  UserModel,
} from '../api/user';
import { getGiftsByUserApi, GiftListItemModel, withdrawGiftApi } from '../api/gift';
import {
  CreateDepositApi,
  createDepositApi,
  getHistoryTransactionsApi,
  HistoryFilters,
  HistoryTransactionsItem,
} from '../api/history';
import { getWalletsApi, LootboxWalletsModel } from '../api/wallets';
import { initialModalState, ModalType } from './modals';

interface Actions {
  getUserInfo: () => void;
  getLootboxes: () => void;
  getGifts: () => void;
  getHistoryTransactions: (filters: HistoryFilters) => void;
  withdrawGift: (giftId: string, cb: () => void, errCb: () => void) => void;
  telegramCallback: (tgUser: TelegramUserModel) => void;
  getWallets: () => void;
  showModal: (v: ModalType) => void;
  closeModal: (v: ModalType) => void;
  closeModals: () => void;
  createDeposit: (p: CreateDepositApi, errCb: () => void) => void;
}

interface State {
  lootboxes: GetLootBoxListItem[];
  gifts: GiftListItemModel[];
  history: HistoryTransactionsItem[];
  wallets: LootboxWalletsModel['items'];
  loading: boolean;
  userLoading: boolean;
  opening: boolean;
  user: UserModel | null;
  userId: string;
  sessionID: string;
  modals: Record<ModalType, boolean>;
}

export const useLootboxStore = create<State & Actions>()((set, get) => ({
  userLoading: true,
  loading: true,
  opening: true,
  lootboxes: [],
  gifts: [],
  history: [],
  wallets: [],
  user: null,
  userId: Cookies.get('userID') || '',
  sessionID: Cookies.get('sessionID') || '',
  modals: initialModalState,

  getLootboxes: () => {
    getLootboxesApi(get().userId)
      .then(({ data }) => {
        const lootboxes = (data as unknown as GetLootBoxListItem[]).reduce((acc, item) => {
          if (item.gifts.length) {
            return [...acc, item];
          }
          return acc;
        }, [] as GetLootBoxListItem[]);
        set({ lootboxes, loading: false });
      })
      .catch(() => {
        set({ loading: false });
      });
  },
  getUserInfo: () => {
    getUserInfoApi(get().userId)
      .then(({ data: user }) => {
        set({ user });
        Cookies.set('userID', user.id);
      })
      .finally(() => {
        set({ userLoading: false });
      });
  },
  telegramCallback: (tgUser) => {
    telegramCallbackApi(tgUser).then(({ data: user }) => {
      set({ user, userId: user.id, userLoading: true });
      Cookies.set('userID', user.id);

      get().getUserInfo();
    });
  },
  getGifts: () => {
    getGiftsByUserApi(get().userId)
      .then(({ data: gifts }) => {
        set({ gifts, loading: false });
      })
      .catch(() => {
        set({ loading: false });
      });
  },
  withdrawGift: (id, cb, errCb) => {
    withdrawGiftApi(get().userId, id)
      .then(() => {
        set({ loading: false });
        cb();
      })
      .catch(() => {
        set({ loading: false });
        errCb();
      });
  },
  getHistoryTransactions: (filters: HistoryFilters) => {
    getHistoryTransactionsApi({ userId: get().userId, ...filters })
      .then(({ data: history }) => {
        set({ history, loading: false });
      })
      .catch(() => {
        set({ loading: false });
      });
  },
  getWallets: () => {
    getWalletsApi().then(({ data }) => {
      set({ wallets: data.items });
    });
  },
  showModal: (modal) => {
    set({ modals: { ...get().modals, [modal]: true } });
  },
  closeModal: (modal) => {
    set({ modals: { ...get().modals, [modal]: false } });
  },
  closeModals: () => {
    set({ modals: { ...initialModalState } });
  },
  createDeposit: (p, errCb) => {
    createDepositApi(p)
      .then(() => {
        get().closeModals();
        get().showModal(ModalType.DepositSuccess);
      })
      .catch(errCb);
  },
}));
