import React, { useEffect, useState } from 'react';
import { Slider } from '../components/slider/Slider';
import { CaseItems } from '../components/cases/case-items/CaseItems';
import { RollerContainer } from '../components/roller-container/RollerContainer';
import { useMediaQuery } from '../hooks/useMediaQuery';
import { useLootboxStore } from '../store';
import { Spinner } from '../components/spinner';
import { ModalWin } from '../components/cases/modal-win';
import { GiftItem, openLootboxApi, prizeMock } from '../api/cases';
import { requestRepeater } from '../utils/request-repeater';
import { message } from 'antd';
import { useTranslation } from 'react-i18next';

type Props = {
  handleOpenLoginModal: () => void;
  handleOpenWarningDepositModal: () => void;
};
export const CasesContainer = ({ handleOpenLoginModal, handleOpenWarningDepositModal }: Props) => {
  const { t } = useTranslation();
  const [messageApi, contextHolder] = message.useMessage();
  const { getLootboxes, lootboxes, loading, user, userId, getUserInfo } = useLootboxStore();
  const isMobile = useMediaQuery('(max-width: 768px)');
  const [start, setStart] = useState(false);
  const [isOpenModal, setIsOpenModal] = useState(false);
  const [activeCase, setActiveCase] = useState(0);
  const [prize, setPrize] = useState<GiftItem>();
  const [opening, setOpening] = useState(false);
  // correct version
  const onOpenLoot = () => {
    if (user?.id) {
      if (+user.attempt_balance) {
        setOpening(true);
        requestRepeater(() => openLootboxApi(userId, lootboxes[activeCase].id))
          .then((v) => {
            setPrize(v.data);
            if (isMobile) {
              setIsOpenModal(true);
            } else {
              setStart((prevState) => !prevState);
            }
          })
          .catch(() => {
            onCloseWinModal();
            messageApi.open({ content: t('SYSTEM_ERROR'), type: 'error' });
          })
          .finally(() => setTimeout(() => setOpening(false), 500));
      } else {
        handleOpenWarningDepositModal();
      }
    } else {
      handleOpenLoginModal();
    }
  };

  const onCloseWinModal = () => {
    setIsOpenModal(false);
    setPrize(undefined);
    getUserInfo();
  };

  const onGiftDefined = () => {
    setOpening(false);
    setIsOpenModal(true);
    setStart(false);
  };

  useEffect(() => {
    getLootboxes();
  }, []);

  if (loading) {
    return <Spinner full />;
  }

  // вынести лоадер на кнопку открытия и когда отработает запрос, то запускать роллер и открытие модалки выигрыша

  return (
    <div style={{ margin: lootboxes.length ? '0 0 24px 0' : 'auto 0' }}>
      {contextHolder}
      {lootboxes.length ? (
        <>
          <Slider onOpen={onOpenLoot} lootboxes={lootboxes} onChangeSlide={setActiveCase} opening={opening} />
          {!isMobile && (
            <RollerContainer
              isRunning={start}
              gifts={lootboxes[activeCase].gifts}
              prize={prize}
              onGiftDefined={onGiftDefined}
            />
          )}
          <CaseItems gifts={lootboxes[activeCase].gifts} />
          {isOpenModal ? (
            <ModalWin gift={prize} onClose={onCloseWinModal} isDesktop={!isMobile} loading={opening} />
          ) : null}
        </>
      ) : (
        <div style={{ color: 'white', textAlign: 'center', margin: '40px auto', fontSize: '32px' }}>
          {t('NO_LOOT')}
          <br />
          <br />
          {t('NO_LOOT_CON')}
        </div>
      )}
    </div>
  );
};
