import React from 'react';
import { useTranslation } from "react-i18next";
import styles from './index.module.css';
import LanguageSelect from "../language-select/LanguageSelect";
import MainLogo from "../../assets/images/png/mainLogo.png";
import TgImg from 'src/assets/images/svg/telegram-white-logo.svg'
import EmailImg from 'src/assets/images/svg/email.svg'

type Props = {
};
export const Footer = ({}: Props) => {
  const { t } = useTranslation();

  return (
    <>
      <div className={styles.container}>
        <div>
          <img src={MainLogo} className={styles.logo} alt="main-logo"/>
        </div>
        <div className={styles.rightBlock}>
          <div className={styles.contacts}>
            <a href="mailto:support@cryptl.io"><img src={EmailImg} alt="email"/></a>
            <a href="https://t.me/PremiumRussia" target={'_blank'}><img src={TgImg} alt="tg"/></a>
          </div>
          <LanguageSelect size='small'/>
        </div>
      </div>
      <div className={styles.rights}>© 2024 All Rights Reserved</div>
    </>
  );
};
