import React, { FC, useCallback, useEffect, useMemo, useState } from 'react';
import {App, Button, message, Modal, Select} from 'antd';
import { Space } from 'src/components/ant/space';
import {type Address, parseUnits} from 'viem';

import { useWalletClient, WalletClient, useSwitchNetwork } from 'wagmi';
import { ContractArgs, contractsConfig } from 'src/utils/wagmi/contracts';
import {uniqBy} from "../../../../utils/uniqBy";
import {chainIcons} from "../../../../utils/wagmi/icons";
import styles from "../index.module.css";
import {useLootboxStore} from "../../../../store";
import {UserModel} from "../../../../api/user";
import {ConnectWalletModal} from "./ConnectWalletModal";
import {useTranslation} from "react-i18next";
import {TotalContent} from "../total-content";

interface OnWriteContract {
  walletClient: WalletClient;
  value: number;
  user: UserModel;
}

interface Props {
  onCancel?: () => void;
  onSubmitFinalPayment: (hash: string, blockchain_id: string, user_address: string) => void;
  volume: number;
}

type ContractsByChain = Record<number, ContractArgs>;

export const ConnectedWalletPaymentModal: FC<Props> = (props) => {
  const { onCancel, volume, onSubmitFinalPayment } = props;
  // const { t } = useTranslation();
  const [messageApi, contextHolder] = message.useMessage();
  const [isConnectModalOpen, setIsConnectModalOpen] = useState(false);

  const { switchNetworkAsync } = useSwitchNetwork();

  const {
    wallets: walletsData, user
  } = useLootboxStore();

  const {
    data: walletClient,
    isFetchedAfterMount,
    isFetching,
    isError
  } = useWalletClient();

  const [currentNetwork, setCurrentNetwork] = useState<WalletClient['chain']['id']>(
    walletClient?.chain.id || 1,
  );

  const contracts = useMemo<ContractsByChain>(() => {
    const availableAddresses: ContractsByChain = {};

    walletsData.forEach((available) => {
      availableAddresses[available.global_id] = {
        ...contractsConfig[available.global_id],
        recipient: available.address as Address,
      };
    });

    return availableAddresses;
  }, [walletsData, walletClient]);

  const onWriteContract = useCallback(
    async ({ walletClient, value }: OnWriteContract) => {
      const payload = {
        address: contracts[currentNetwork].address,
        account: walletClient.account,
        abi: contracts[currentNetwork].abi,
        functionName: 'transfer',
        args: [
          contracts[currentNetwork].recipient,
          parseUnits(value.toString(), contracts[currentNetwork].units),
        ],
      }
      return await walletClient.writeContract(payload);
    },
    [contracts, currentNetwork, walletClient],
  );

  const onPay = useCallback(async () => {
    try {
      if (user) {
        if (!walletClient) {
          setIsConnectModalOpen(true);
        } else {
          const blockchainId = walletsData.find(({ global_id}) => global_id === currentNetwork)?.blockchain_id || '';
          const hash = await onWriteContract({
            user,
            walletClient,
            value: volume,
          });
          onSubmitFinalPayment(hash, blockchainId, walletClient.account.address);

          messageApi.open({ content: 'Платеж выполнен! В ближайшее время средства будут зачислены на Ваш баланс', type: "success" });
          // todo write callback
          onCancel?.();
        }
      }
    } catch (e) {
      console.error(e);
      messageApi.open({ content: `${e}`, type: "error" });
      onCancel?.();
    }
  }, [walletClient, switchNetworkAsync, user, currentNetwork, walletsData?.length]);

  const networkOptions = useMemo(() => {
    return uniqBy(walletsData, (v) => v.global_id).map((wallet) => ({
      value: wallet.global_id,
      label: (
        <Space size={8} full>
          {chainIcons[wallet.global_id] && <img src={chainIcons[wallet.global_id]} alt={wallet.blockchain_name} />}
          {wallet.blockchain_name}
        </Space>
      ),
      data: { address: wallet.address },
    }));
  }, [walletsData]);

  useEffect(() => {
    if (!isFetchedAfterMount && !isFetching) {
      setIsConnectModalOpen(true);
    }
  }, [walletClient, isFetchedAfterMount]);

  useEffect(() => {
    console.log('walletClient?.chain.id', walletClient?.chain.id);
    if (walletClient?.chain.id) {
      setCurrentNetwork(walletClient.chain.id);
    }
  }, [walletClient?.chain.id]);

  return (
    <>
      {contextHolder}
      <Modal destroyOnClose footer={null} open onCancel={onCancel}>
        <div className={styles.title}>Оплатить привязанным кошельком</div>
        <Select
          size="large"
          placeholder="Choose network"
          value={currentNetwork}
          onSelect={(value) => {
            if (walletClient?.switchChain) {
              walletClient
                ?.switchChain({id: value})
                .then(() => {
                  setCurrentNetwork(value);
                })
                .catch((e) => {
                  messageApi.open({ content: 'Cant switch chain', type: "error" });
                  console.warn(e);
                });
            }
          }}
          style={{width: '100%'}}
          options={networkOptions}
          className={styles.select}
        />

        <TotalContent volume={volume} />

        <Button size="large" block type="primary" disabled={!walletClient || isError} onClick={onPay}>
          Пополнить баланс
        </Button>
        <ConnectWalletModal
          chainId={currentNetwork}
          open={isConnectModalOpen}
          onCancel={() => setIsConnectModalOpen(false)}
        />
      </Modal>
    </>
  );
};
