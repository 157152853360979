import React, { useMemo } from 'react';
import { Flex } from 'antd';
import { Typography } from '../../ant/typography';
import ImgBaseNft from 'src/assets/images/svg/img-base-nft.svg';
import ImgBaseCoins from 'src/assets/images/svg/img-base-coins.svg';
import ImgBaseTshirts from 'src/assets/images/svg/img-base-tshirt.svg';
import { GetLootBoxListItem } from '../../../api/cases';
import { GiftTypeEnum } from '../../../api/gift';
import styles from './caseItems.module.css';
import { GiftItemCard } from '../gift-item';
import { getGiftSize } from '../gift-item/getData';
import { useMediaQuery } from '../../../hooks/useMediaQuery';
import { useTranslation } from "react-i18next";

type Props = {
    gifts: GetLootBoxListItem['gifts'];
};

export const CaseItems = ({ gifts }: Props) => {
  const { t } = useTranslation();
  const isDesktop = useMediaQuery('(min-width: 1100px)');
    const giftIndexDivider = gifts.length > 6 ? 4 : 1;

    return (
        <div>
            <Flex justify="center" align="center" className={styles.headerText}>
              {t('CHEST_CONTENTS')}
            </Flex>
            {isDesktop ? (
                <div className={styles.wrapper}>
                    <div className={styles.column}>
                        {gifts.slice(0, giftIndexDivider).map((v, index) => (
                            <GiftItemCard key={v.id} data={v} size={getGiftSize(index, isDesktop)} />
                        ))}
                    </div>
                    <div className={styles.column}>
                        {gifts.slice(giftIndexDivider, gifts.length).map((v, index) => (
                            <GiftItemCard key={v.id} data={v} size={"small"} />
                        ))}
                    </div>
                </div>
            ) : (
                <div className={styles.wrapper}>
                    {gifts.map((v, index) => (
                        <GiftItemCard key={v.id} data={v} size={getGiftSize(index, isDesktop)} />
                    ))}
                </div>
            )}

            {/*<Row gutter={[12, 12]}>*/}
            {/*  <Col span={isMobile ? 24 : 12}>*/}
            {/*    <img src={CaseItemsIcon} className={styles.itemImg} />*/}
            {/*  </Col>*/}

            {/*  <Col span={isMobile ? 24 : 12}>*/}
            {/*    <img src={CaseItems2Icon} className={styles.itemImg} />*/}
            {/*  </Col>*/}
            {/*</Row>*/}

            {/*<Row gutter={[12, 12]}>*/}
            {/*  <Col span={12}>*/}
            {/*    <Row gutter={[8, 8]}>*/}
            {/*      <Col span={24}>*/}
            {/*        /!* большой *!/*/}
            {/*        <div className={styles.block}></div>*/}
            {/*      </Col>*/}
            {/*      <Col span={8}>*/}
            {/*        /!* средний *!/*/}
            {/*        <div className={styles.block}></div>*/}
            {/*      </Col>*/}
            {/*      <Col span={8}>*/}
            {/*        /!* средний *!/*/}
            {/*        <div className={styles.block}></div>*/}
            {/*      </Col>*/}
            {/*      <Col span={8}>*/}
            {/*        /!* средний *!/*/}
            {/*        <div className={styles.block}></div>*/}
            {/*      </Col>*/}
            {/*    </Row>*/}
            {/*  </Col>*/}

            {/*  <Col span={12}>*/}
            {/*    <Row gutter={[8, 8]}>*/}
            {/*      <Col span={6}>*/}
            {/*        /!* малый *!/*/}
            {/*        <div className={styles.block}></div>*/}
            {/*      </Col>*/}
            {/*      <Col span={6}>*/}
            {/*        /!* малый *!/*/}
            {/*        <div className={styles.block}></div>*/}
            {/*      </Col>*/}
            {/*      <Col span={6}>*/}
            {/*        /!* малый *!/*/}
            {/*        <div className={styles.block}></div>*/}
            {/*      </Col>*/}
            {/*      <Col span={6}>*/}
            {/*        /!* малый *!/*/}
            {/*        <div className={styles.block}></div>*/}
            {/*      </Col>*/}
            {/*      <Col span={6}>*/}
            {/*        /!* малый *!/*/}
            {/*        <div className={styles.block}></div>*/}
            {/*      </Col>*/}
            {/*      <Col span={6}>*/}
            {/*        /!* малый *!/*/}
            {/*        <div className={styles.block}></div>*/}
            {/*      </Col>*/}
            {/*      <Col span={6}>*/}
            {/*        /!* малый *!/*/}
            {/*        <div className={styles.block}></div>*/}
            {/*      </Col>*/}
            {/*      <Col span={6}>*/}
            {/*        /!* малый *!/*/}
            {/*        <div className={styles.block}></div>*/}
            {/*      </Col>*/}

            {/*      <Col span={6}>*/}
            {/*        /!* малый *!/*/}
            {/*        <div className={styles.block}></div>*/}
            {/*      </Col>*/}
            {/*      <Col span={6}>*/}
            {/*        /!* малый *!/*/}
            {/*        <div className={styles.block}></div>*/}
            {/*      </Col>*/}
            {/*      <Col span={6}>*/}
            {/*        /!* малый *!/*/}
            {/*        <div className={styles.block}></div>*/}
            {/*      </Col>*/}
            {/*      <Col span={6}>*/}
            {/*        /!* малый *!/*/}
            {/*        <div className={styles.block}></div>*/}
            {/*      </Col>*/}
            {/*    </Row>*/}
            {/*  </Col>*/}
            {/*</Row>*/}
        </div>
    );
};
