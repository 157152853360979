import React, { useState } from 'react';
import { useTranslation } from "react-i18next";
import { Button, Flex, Input, Modal } from "antd";
import GoBackIcon from 'src/assets/images/svg/goBack.svg';
import { Typography } from '../../../ant/typography';

import styles from './manual-deposit-form.module.css';

type Props = {
  onClose: () => void;
  onSubmit: (amount: number) => void;
}

export const ManualDepositForm = ({ onClose, onSubmit }: Props) => {
  const { t } = useTranslation();
  const [amount, setAmount] = useState(25);

  const handleSubmit = () => {
    onSubmit(amount);
  }

  const handleChangeInput = (e: any) => {
    setAmount(e?.target?.value ?? undefined);
  }

  return (
    <Modal
      open
      title={<img className={styles.goBackImg} src={GoBackIcon} alt='GoBackIcon' onClick={onClose} />}
      footer={null}
      onCancel={onClose}
    >
      <Flex justify='center' align='center' vertical>
        <Typography.Title style={{margin: '16px 0 12px'}}>
          {t('WALLET.TYPE_SUM')}
        </Typography.Title>

        <div className={styles.secondaryText}>
          {t('WALLET.TEXT_WARNING')}
        </div>
      </Flex>

      <div className={styles.label}>{t('WALLET.SUM')}</div>
      <Input className={styles.input} type='number' size='large' suffix='USDT' value={amount} onChange={handleChangeInput} />

      <Button type='primary' size='large' block htmlType='submit' disabled={!amount} onClick={handleSubmit}>{t('WALLET.PAY')}</Button>
    </Modal>
  );
};
