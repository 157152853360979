import React from 'react';
import styles from './index.module.css';
import { useTranslation } from "react-i18next";

type Props = {
  volume: number;
};
export const TotalContent = ({ volume }: Props) => {
  const { t } = useTranslation();

  return (
    <div className={styles.totalWrapper}>
      <div>{t('WALLET.TOTAL')}:</div>
      <div>
        <span>{volume}</span> USDT
      </div>
    </div>
  );
}

