import React from 'react';
import { Button, Modal } from 'antd';
import styles from './index.module.css';
import SuccessImg from "src/assets/images/png/success.png";
import { useTranslation } from "react-i18next";

type Props = {
  onClose: () => void;
};
export const ModalWithdrawRequest= (props: Props) => {
  const { t } = useTranslation();
  const { onClose } = props;
  return (
    <Modal open footer={null} onCancel={onClose} className={styles.modal}>
      <img className={styles.logo} src={SuccessImg} alt=""/>
      <div className={styles.title}>Заявка отправлена</div>
      <div className={styles.subtitle}>
        {t('WITHDRAW_REQUEST_TEXT')}
      </div>
      <Button className={styles.button} type={"primary"} onClick={onClose}>
        {t('WITHDRAW_REQUEST_BTN')}
      </Button>
    </Modal>
  );
};
