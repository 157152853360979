import React from 'react';
import { Modal } from 'antd';
import styles from './index.module.css';
import LoginImg from "src/assets/images/png/login.png";
import {TelegramUserModel} from "../../../api/user";
// @ts-ignore
import TelegramLoginButton from "react-telegram-login";

type Props = {
  onClose: () => void;
  handleLogin: (v: TelegramUserModel) => void;
};
export const ModalTelegram = (props: Props) => {
  const { onClose, handleLogin } = props;

  return (
    <Modal open footer={null} onCancel={onClose} className={styles.modal}>
      <div className={styles.gradient}></div>
      <img className={styles.logo} src={LoginImg} alt=""/>
      <div className={styles.title}>Войдите в аккаунт</div>
      <div className={styles.subtitle}>
        Войдите в аккаунт и пополните баланс для того чтобы открыть лутбокс.
      </div>
      <TelegramLoginButton
        dataOnauth={handleLogin}
        usePic={false}
        buttonSize={'medium'}
        botName={process.env.REACT_APP_BOT_NAME}
      />
    </Modal>
  );
};
