import React, {memo, useCallback, useRef} from 'react';
import { Swiper, SwiperSlide } from 'swiper/react';
import { Button } from 'antd';
import { Typography } from '../ant/typography';
import { useMediaQuery } from '../../hooks/useMediaQuery';
import classNames from 'classnames';
import Logo from '../../assets/images/png/case_1.png';
import { Navigation, Pagination } from 'swiper/modules';
import LeftIcon from 'src/assets/images/svg/left.svg';
import RightIcon from 'src/assets/images/svg/right.svg';
// Import Swiper styles
import 'swiper/css';
import 'swiper/css/pagination';
import 'swiper/css/navigation';
// another styles
import './global-style.css';
import styles from './slider.module.css';
import { GetLootBoxListItem } from '../../api/cases';
import { useTranslation } from "react-i18next";
import {getImageUrlById} from "../../utils/get-image";

type Props = {
  onOpen: () => void;
  opening: boolean;
  onChangeSlide: (i: number) => void;
  lootboxes: GetLootBoxListItem[];
};

export const Slider = ({ onOpen, lootboxes, onChangeSlide, opening }: Props) => {
  const { t } = useTranslation();

  const isMobile = useMediaQuery('(max-width: 768px)');

  const navigationPrevRef = React.useRef(null);
  const navigationNextRef = React.useRef(null);

  const sliderRef = useRef(null);

  const handlePrev = useCallback(() => {
    if (!sliderRef.current) return;
    // @ts-expect-error
    sliderRef.current.swiper.slidePrev();
  }, []);

  const handleNext = useCallback(() => {
    if (!sliderRef.current) return;
    // @ts-expect-error
    sliderRef.current.swiper.slideNext();
  }, []);

  return (
    <Swiper
      ref={sliderRef}
      className={styles.container}
      slidesPerView={isMobile ? 1 : 2}
      spaceBetween={0}
      hashNavigation={{
        watchState: true,
      }}
      pagination={{ enabled: false }}
      effect="fade"
      navigation={{
        prevEl: navigationPrevRef.current,
        nextEl: navigationNextRef.current,
      }}
      onBeforeInit={(swiper: any) => {
        swiper.params.navigation.prevEl = navigationPrevRef.current;
        swiper.params.navigation.nextEl = navigationNextRef.current;
      }}
      modules={[Navigation]}
      onNavigationNext={(s: {activeIndex: number}) => onChangeSlide(s.activeIndex)}
      onNavigationPrev={(s: {activeIndex: number}) => onChangeSlide(s.activeIndex)}
      slidePrevClass={styles.prevSlide}
      slideNextClass={styles.nextSlide}
      centeredSlides
      parallax
    >
      {lootboxes.map((item) => (
        <SwiperSlide key={item.id} className={styles.slide}>
          <div className={styles.bg} style={{ left: '17%' }}></div>
          <div className={styles.bg} style={{ right: '18%' }}></div>
          <img src={getImageUrlById(item.photo_id)} alt="" className={styles.img} />
          <div className={styles.slideInfo}>
            <Typography.Title>{item.name}</Typography.Title>
            <Button type="primary" className={styles.slideButton} onClick={onOpen} loading={opening}>
              {t('SLIDER_OPEN_FOR')} {item.price} USDT
            </Button>
          </div>
        </SwiperSlide>
      ))}
      <Button
        ref={navigationPrevRef}
        type="primary"
        shape="circle"
        className={classNames(
          styles.buttonsSwitchers,
          styles.buttonsLeft,
        )}
      >
        <img src={LeftIcon} width={isMobile ? 24 : 40} height={isMobile ? 24 : 40} alt="left icon slider" />
      </Button>

      <Button
        ref={navigationNextRef}
        type="primary"
        shape="circle"
        className={classNames(
          styles.buttonsSwitchers,
          styles.buttonsRight,
        )}
      >
        <img src={RightIcon} width={isMobile ? 24 : 40} height={isMobile ? 24 : 40} alt="right icon slider" />
      </Button>
    </Swiper>
  );
}
