import React, { useEffect, useMemo, useState } from 'react';
import type { TableColumnsType } from 'antd';
import { useTranslation } from 'react-i18next';
import { Button, Card, Flex, message, Table } from 'antd';
import WarningIcon from 'src/assets/images/svg/warning.svg';
import CheckIcon from 'src/assets/images/svg/check.svg';
import { Typography } from 'src/components/ant/typography';

import styles from './win.module.css';
import { GiftListItemModel, GiftTypeEnum, StatusWin } from '../../api/gift';
import { useLootboxStore } from '../../store';
import { ModalWithdrawRequest } from '../../components/modals/modal-withdraw-request';
import { useMediaQuery } from '../../hooks/useMediaQuery';
import { getImageUrlById } from '../../utils/get-image';
import { formatFullDateAndTimeRu, formatFullDateRu } from '../../utils/formatters';

export const WinContainer = () => {
  const { t } = useTranslation();
  const [messageApi, contextHolder] = message.useMessage();
  const { getGifts, gifts, withdrawGift } = useLootboxStore();
  const isSmallMobile = useMediaQuery('(max-width: 575px)');
  const [isOpenRequestModal, setIsOpenRequestModal] = useState(false);

  const handleWithdraw = (id: string) => {
    withdrawGift(
      id,
      () => {
        setIsOpenRequestModal(true);
        getGifts();
      },
      () => messageApi.open({ content: t('WIN_ERROR'), type: 'error' })
    );
  };

  const columns: TableColumnsType<GiftListItemModel> = useMemo(
    () => [
      {
        title: t('WIN_TABLE.NAME'),
        dataIndex: 'name',
        render: (_, record) => (
          <Flex gap="small" justify="flex-start" align="center">
            <div className={styles.imgWrapper}>
              <img src={getImageUrlById(record.photo_id)} alt="" />
            </div>
            <div>
              {isSmallMobile ? (
                <div className={styles.date}>{formatFullDateAndTimeRu(record.received_at)}</div>
              ) : null}
              <div className={styles.value}>
                {record.type === GiftTypeEnum.Merch || record.type === GiftTypeEnum.Nft
                  ? record.name
                  : `${record.price} ${record.currency}`}
              </div>
            </div>
          </Flex>
        ),
      },
      {
        title: t('WIN_TABLE.DATE'),
        dataIndex: 'received_at',
        render: (value) => <span className={styles.value}>{formatFullDateAndTimeRu(value)}</span>,
        hidden: isSmallMobile,
      },
      {
        title: '',
        dataIndex: 'status',
        render: (value, record) => {
          if (value === StatusWin.New) {
            return (
              <Button
                type="primary"
                className={styles.statusButton}
                size={isSmallMobile ? 'small' : 'middle'}
                onClick={() => handleWithdraw(record.id)}
              >
                {t('WIN_TABLE.WITHDRAW')}
              </Button>
            );
          }
          return (
            <Button disabled className={styles.statusButton} size={isSmallMobile ? 'small' : 'middle'}>
              {value === StatusWin.Requested ? null : <img src={CheckIcon} alt={value} />}
              {value === StatusWin.Requested ? t('WIN_TABLE.STATUS_IN_PROGRESS') : t('WIN_TABLE.STATUS_IN_DONE')}
            </Button>
          );
        },
      },
    ],
    [isSmallMobile]
  );

  useEffect(() => {
    getGifts();
  }, []);

  return (
    <div style={{ marginBottom: '24px' }}>
      {contextHolder}
      <Card bordered styles={{ body: { padding: '16px 24px' } }} className={styles.card}>
        <div className={styles.container}>
          <img src={WarningIcon} />
          <Typography.Text className={styles.text} size="middle">
            {t('WITHDRAW_REQUEST_TEXT')}
          </Typography.Text>
        </div>
      </Card>

      <Table
        className={styles.table}
        columns={columns}
        dataSource={gifts}
        rowKey="id"
        pagination={{ position: ['bottomCenter'] }}
        size={isSmallMobile ? 'small' : 'middle'}
      />
      {isOpenRequestModal ? <ModalWithdrawRequest onClose={() => setIsOpenRequestModal(false)} /> : null}
    </div>
  );
};
