import React, { FC } from 'react';
import { Typography } from 'src/components/ant/typography';
import { App, Button, Modal, ModalProps } from 'antd';
import { WagmiConnectorsIds } from 'src/utils/wagmi/config';
import { Connector, useAccount, useConnect, useDisconnect, useSignMessage } from 'wagmi';
import { wagmiConnectorsIconMap } from 'src/components/connect-wallet-widget';
import { Space } from 'src/components/ant/space';
import { useLootboxStore } from "../../../../store";
import { useTranslation } from "react-i18next";

interface Props extends Pick<ModalProps, 'open'> {
  onCancel?: () => void;
  chainId?: number;
}

export const ConnectWalletModal: FC<Props> = (props) => {
  const { t } = useTranslation();
  const { message } = App.useApp();
  const { open, onCancel, chainId } = props;
  const { user } = useLootboxStore();

  const { address, isConnected } = useAccount();
  const { connectors, isLoading, pendingConnector, connectAsync } = useConnect({ chainId });
  const { disconnect } = useDisconnect();

  // const dispatch = useAppDispatch();

  const { signMessageAsync } = useSignMessage({
    message: 'Cryptl',
  });

  const onAddWallet = async (connector: Connector) => {
    try {
      if (user) {
        await connectAsync({ connector, chainId });
        await signMessageAsync();
        // NOTE: Metamask use CROSS CHAIN ADDRESS (only one connection and signature)
        // dispatch(addWallet({ id: user.id, wallet: { address: account, signature } }));
        onCancel?.();
      }
    } catch (e) {
      // @ts-ignore
      message.error(e.message);
      disconnect();
    }
  };

  return (
    <Modal open={open} footer={null} onCancel={onCancel}>
      <Typography.Title level={2} style={{ marginBottom: 24 }}>
        {!isConnected ? t('WALLET.CHOOSE_WAL') : t('WALLET.WAL_CONNECTED')}
      </Typography.Title>
      {!isConnected ? (
        <Space size={16} direction="vertical" full>
          {connectors.map((connector) => (
            <Button
              block
              size="large"
              style={{ display: 'flex', alignItems: 'center', justifyContent: 'center' }}
              loading={isLoading && connector.id === pendingConnector?.id}
              disabled={!connector.ready}
              key={connector.id}
              onClick={async () => await onAddWallet(connector)}
            >
              <img src={wagmiConnectorsIconMap[connector.id as WagmiConnectorsIds]} alt=""/>
              {connector.name}
              {!connector.ready && t('WALLET.INSTALL')}
            </Button>
          ))}
        </Space>
      ) : (
        <Space size={16} direction="vertical">
          <Typography.Text>{address}</Typography.Text>
          <Typography.Text>{t('WALLET.SIGN')}</Typography.Text>
        </Space>
      )}
    </Modal>
  );
};
