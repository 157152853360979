import React, { FC, useMemo } from 'react';
import { Select } from 'antd';
import { useTranslation } from "react-i18next";
import { Languages } from '../../i18n';
import styles from './LanguageSelect.module.scss';
import { Typography } from '../ant/typography';
import { SizeType } from 'antd/es/config-provider/SizeContext';
import classNames from 'classnames';
import { getSupportedApiLocale } from "./get-supported-api-locale";
import { LanguagesMap } from "./language-select-modal/constants";

interface Props {
  value?: string;
  onChange?: () => string;
  size?: SizeType;
  noStyle?: boolean;
}

const LanguageSelect: FC<Props> = (props) => {
  const { i18n } = useTranslation();
  const { value, onChange, size = 'middle', noStyle } = props;

  const onChangeLanguage = (lng: string) => {
    i18n.changeLanguage(lng);
  };

  const language = useMemo(() => {
    if (value) {
      return value;
    }

    return getSupportedApiLocale(i18n.language);
  }, [i18n.language, value]);

  return (
    <Select
      style={{ width: '90px' }}
      className={classNames({ [styles.languageSelect]: !noStyle })}
      size={size}
      defaultValue={onChange ? value : language}
      onSelect={onChange || onChangeLanguage}
      suffixIcon={null}
      options={[
        {
          value: Languages.EN,
          label: (
            <div className={styles.languageSelect__option}>
              {LanguagesMap[Languages.EN].icon}
              <Typography.Text>{LanguagesMap[Languages.EN].name}</Typography.Text>
            </div>
          ),
        },
        {
          value: Languages.RU,
          label: (
            <div className={styles.languageSelect__option}>
              {LanguagesMap[Languages.RU].icon}
              <Typography.Text>{LanguagesMap[Languages.RU].name}</Typography.Text>
            </div>
          ),
        },
      ]}
    />
  );
};

export default LanguageSelect;
