export enum ModalType {
  Telegram = 'Telegram',
  NoMoney = 'NoMoney',
  Deposit = 'Deposit',
  DepositSuccess = 'DepositSuccess',
  WithdrawRequest = 'WithdrawRequest',
  ChoosePaymentWay = 'ChoosePaymentWay',
  QRPayment = 'QRPayment',
  ConnectWallet = 'ConnectWallet',
  WalletPayment = 'WalletPayment'
}

export const initialModalState: Record<ModalType, boolean> = {
  [ModalType.Telegram]: false,
  [ModalType.NoMoney]: false,
  [ModalType.Deposit]: false,
  [ModalType.DepositSuccess]: false,
  [ModalType.WithdrawRequest]: false,
  [ModalType.ChoosePaymentWay]: false,
  [ModalType.QRPayment]: false,
  [ModalType.ConnectWallet]: false,
  [ModalType.WalletPayment]: false
}