import Lottie, { useLottie } from 'lottie-react';
import React, {FC, useEffect } from 'react';
import anim from '../../assets/animations/lootbox-open-new.json';

type Props = {
  onFinishedAnimation?: () => void;
};
export const LootboxOpen: FC<Props> = ({ onFinishedAnimation }) => {
  const { play, View, setSpeed } = useLottie({
    animationData: anim,
    loop: false,
    autoplay: false,
    onComplete: onFinishedAnimation,
  });

  useEffect(() => {
    setTimeout(() => {
      setSpeed(0.75)
      play()
    }, 200);
  }, [])

  return (
    <div style={{ position: "absolute" }}>
      {View}
    </div>
  );
};
