import React, { useEffect, useState } from 'react';
import { useTranslation } from "react-i18next";
import { Modal } from 'antd';
import { useLootboxStore } from '../../../store';
import { QRPaymentContent } from './qr-payment-content';
import { ChoosePaymentWayContent } from './choose-payment-way-content';
import styles from './index.module.css';

type Props = {
  onClose: () => void;
  goToWalletPayment: () => void;
  volume: number;
  onSubmitQrPayment: (hash: string, blockchain_id: string, user_address?: string) => void;
};
export const ModalPayment = (props: Props) => {
  const { t } = useTranslation();
  const [step, setStep] = useState(0);
  const { wallets, getWallets } = useLootboxStore();
  const { onClose, volume, goToWalletPayment, onSubmitQrPayment } = props;

  useEffect(() => {
    getWallets();
  }, []);

  return (
    <Modal open footer={null} onCancel={onClose} className={styles.modal}>
      <div className={styles.title}>{t('WALLET.TOP_UP')}</div>

      {step === 0 ? (
        <ChoosePaymentWayContent
          goToQrPayment={() => setStep(1)}
          goToWalletPayment={goToWalletPayment}
          volume={volume}
        />
      ) : null}
      {step === 1 ? (
        <QRPaymentContent
          walletsData={wallets}
          prev={() => setStep(0)}
          onSubmit={onSubmitQrPayment}
          volume={volume}
        />
      ) : null}
    </Modal>
  );
};
