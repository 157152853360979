import api from 'src/api/index';
import CaseImage from 'src/assets/images/png/case_1.png';
import NftBarrelImage from 'src/assets/images/png/nft-barrel.png';
import NftCollectionImage from 'src/assets/images/png/nft-collection.png';
import BitcoinImage from 'src/assets/images/png/bitcoin.png';
import SolanaImage from 'src/assets/images/png/solana.png';
import CardanoImage from 'src/assets/images/png/cardano.png';
import CapImage from 'src/assets/images/png/cap.png';
import TShirtImage from 'src/assets/images/png/tshirt.png';
import { GiftTypeEnum } from './gift';

export type GiftItem = {
  id: string;
  name: string;
  photo_id: string;
  price: number;
  value: string;
  currency: string;
  type: GiftTypeEnum;
};
export type GetLootBoxListItem = {
  id: string;
  name: string;
  photo: string;
  photo_id: string;
  type: string;
  price: number;
  gifts: GiftItem[];
};

export const getLootboxesApi = (user_id?: string) =>
  api.get<GetLootBoxListItem[]>('partner/lootbox/lootbox_list', { ...(!!user_id && { params: { user_id } }) });

export const openLootboxApi = (user_id: string, lootbox_id: string) =>
  api.post<GiftItem>('partner/lootbox/lootbox_open', { user_id, lootbox_id }, { params: { user_id } });

export const prizeMock: GiftItem = {
  id: '80d3c29e-fc1c-40f2-b4b9-ee5e68fb9675',
  type: GiftTypeEnum.Coins,
  name: 'Solana',
  photo_id: '82585ec3-d290-469a-b17c-4c1b99cb69bd',
  price: 1,
  value: '150',
  currency: 'SOL',
};
