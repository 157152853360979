import api from './index';

export type HistoryTransactionsItem = {
  blockchain_id: string;
  created_at: string;
  user_telegram: string;
  hash: string;
  id: string;
  status: HistoryStatus;
  updated_at: string;
  user_address: string;
  user_id: string;
  volume: string;
};
export enum HistoryStatus {
  All = 'ALL',
  Done = 'DONE',
  New = 'NEW',
}

export type HistoryFilters = {
  status?: HistoryStatus;
  from?: string;
  to?: string;
  userId?: string;
};
export const getHistoryTransactionsApi = (params: HistoryFilters) =>
  api.get<HistoryTransactionsItem[]>('partner/deposit_transactions', { params });

export type CreateDepositApi = {
  volume: number;
  blockchain_id: string;
  hash: string;
  user_address?: string;
};
export const createDepositApi = (p: CreateDepositApi) => api.post('partner/deposit_transactions', p);
