import React, { FC, useState } from 'react';
import { WagmiConfig } from 'wagmi';
import { useLootboxStore } from '../../store';
import { ModalTelegram } from './modal-telegram';
import { ModalNoMoney } from './modal-no-money';
import { ModalDeposit } from './modal-deposit/DepositModal';
import { ModalPayment } from './modal-payment';
import { config } from '../../utils/wagmi/config';
import { ConnectedWalletPaymentModal } from './modal-payment/wagmi-payments/ConnectedWalletPaymentModal';
import { ModalDepositSuccess } from './modal-deposit-success';
import { ModalType } from '../../store/modals';
import { message } from 'antd';
import { useTranslation } from 'react-i18next';
import { TelegramUserModel } from '../../api/user';

type Props = {
  handleLogin: (v: TelegramUserModel) => void;
};
export const ModalsContainer: FC<Props> = ({ handleLogin }) => {
  const { t } = useTranslation();
  const [amount, setAmount] = useState<number>(10);
  const [messageApi, contextHolder] = message.useMessage();
  const { modals, showModal, closeModal, closeModals, createDeposit, userId } = useLootboxStore();

  const onSubmitFinalPayment = (hash: string, blockchain_id: string, user_address?: string) => {
    createDeposit(
      {
        hash,
        volume: amount,
        user_address,
        blockchain_id,
      },
      () => {
        messageApi.open({ content: t('SYSTEM_ERROR'), type: 'error' });
      }
    );
  };

  const onSubmitAmountPayment = (amount: number) => {
    setAmount(amount);
    closeModals();
    showModal(ModalType.ChoosePaymentWay);
  };

  const handleOpenDepositFlowModal = () => {
    closeModals();
    showModal(ModalType.Deposit);
  };

  return (
    <>
      {contextHolder}
      {modals.Telegram ? <ModalTelegram handleLogin={handleLogin} onClose={closeModals} /> : null}
      {modals.NoMoney ? (
        <ModalNoMoney onClose={closeModals} handleOpenDepositFlowModal={handleOpenDepositFlowModal} />
      ) : null}
      {modals.Deposit ? <ModalDeposit onClose={closeModals} onSubmit={onSubmitAmountPayment} /> : null}
      {modals.ChoosePaymentWay ? (
        <ModalPayment
          volume={amount}
          onClose={closeModals}
          goToWalletPayment={() => showModal(ModalType.WalletPayment)}
          onSubmitQrPayment={onSubmitFinalPayment}
        />
      ) : null}
      <WagmiConfig config={config}>
        {modals.WalletPayment ? (
          <ConnectedWalletPaymentModal
            onCancel={closeModals}
            volume={amount}
            onSubmitFinalPayment={onSubmitFinalPayment}
          />
        ) : null}
      </WagmiConfig>
      {modals.DepositSuccess ? <ModalDepositSuccess onClose={closeModals} /> : null}
    </>
  );
};
