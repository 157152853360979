import React, {memo, useMemo, useState} from 'react';
import { Card } from 'antd';
import RoulettePro from 'react-roulette-pro';
import classNames from 'classnames';
import { GiftItem } from '../../api/cases';
import { GiftItemCard } from '../cases/gift-item';
import { GiftTypeEnum } from '../../api/gift';
import styles from './rollerContainer.module.css';

type Props = {
  isRunning: boolean;
  onGiftDefined: () => void;
  prize?: GiftItem;
  gifts: GiftItem[];
};
export const RollerContainer = memo(({ isRunning, onGiftDefined, prize, gifts }: Props) => {
  const [revertPrizeIndex, setRevertPrizeIndex] = useState(false);
  const list = useMemo(
    () =>
      [...gifts, ...gifts, ...gifts, ...gifts, ...gifts, ...gifts, ...gifts, ...gifts, ...gifts, ...gifts].map(
        ({ type, photo_id, id }) => ({ id: `${id}_plus_${Math.random()}`, image: (photo_id), text: type })
      ) || [],
    [gifts?.length]
  );

  const prizeIndex = useMemo(() => {
    if (!!prize) {
      return list.findIndex(({id}) => prize?.id === id.split('_plus_')[0]) + ((revertPrizeIndex ? 1 : 6) * gifts.length);
    }

    return -1;
  }, [list.length, prize, revertPrizeIndex]);

  const isFullRunning = isRunning && !!prize && !!prizeIndex && !!list.length;

  const onPrizeDefined = () => {
    onGiftDefined();
    setRevertPrizeIndex(!revertPrizeIndex)
  }

  return (
    <div className={classNames(styles.container, isRunning && styles.active)}>
      <Card>
        <RoulettePro
          start={isFullRunning}
          prizes={list}
          prizeIndex={prizeIndex}
          spinningTime={6}
          options={{ withoutAnimation: true }}
          prizeItemRenderFunction={({ id, text, image }) => (
            <div className={styles.item}>
              <GiftItemCard
                key={id}
                data={{ type: text as GiftTypeEnum, name: '', photo_id: image, id: `${id}`, price: 0, value: '', currency: '' }}
                size={'small'}
                isRollerView
              />
            </div>
          )}
          classes={{ wrapper: styles.wrapper }}
          onPrizeDefined={onPrizeDefined}
        />
      </Card>
    </div>
  );
});
