import React, {useState} from 'react';
import { Button, Modal } from 'antd';
import { GiftItem } from '../../../api/cases';
import { GiftItemCard } from '../gift-item';
import styles from './index.module.css';
import classNames from 'classnames';
import { getGiftColor } from '../gift-item/getData';
import { Spinner } from '../../spinner';
import { LootboxOpen } from '../../lootbox-open';
import { useTranslation } from "react-i18next";

type Props = {
  onClose: () => void;
  gift?: GiftItem;
  isDesktop: boolean;
  loading?: boolean;
};
export const ModalWin = (props: Props) => {
  const { t } = useTranslation();
  const [isFinishedAnimation, setIsFinishedAnimation] = useState(false);
  const { onClose, gift, isDesktop } = props;
  if (isDesktop) {
    return (
      <Modal open footer={null} onCancel={onClose} className={styles.modal}>
        <div className={classNames(styles.gradient, styles[getGiftColor(gift?.type)])}></div>
        <div className={classNames(styles.gift, isFinishedAnimation && styles.giftActive)}>
          <GiftItemCard data={gift!} size={'giant'} isRollerView noBg />
        </div>
        { isFinishedAnimation ? null : (
          <div className={styles.animWrapper}>
            <LootboxOpen onFinishedAnimation={() => setIsFinishedAnimation(true)}/>
          </div>
        )}
        <div className={styles.title}>{gift?.name}</div>
        <div className={styles.subtitle}>
          {t('WIN.TEXT1')} <b>{gift?.name}</b>. {t('WIN.TEXT2')} -{'>'}{' '}
          {t('WIN.TEXT3')}
        </div>
        <Button type={'primary'} onClick={onClose} style={{ width: '100%' }}>
          {t('WIN.TEXT4')}
        </Button>
      </Modal>
    );
  }
  return <MobileModalWin {...props} />;
};

const MobileModalWin = ({ gift, onClose, loading }: Props) => {
  const { t } = useTranslation();
  return (
    <Modal
      open
      footer={null}
      onCancel={onClose}
      className={styles.modal}
      classNames={{ content: classNames(styles.modalContent, loading && styles.loading) }}
    >
      {loading ? (
        <Spinner full />
      ) : (
        <>
          <LootboxOpen />
          <div className={styles.content}>
            <div className={classNames(styles.gradient, styles[getGiftColor(gift?.type)])}></div>
            <GiftItemCard data={gift!} size={'giant'} isRollerView/>
            <div className={styles.title}>{gift?.name}</div>
            <div className={styles.subtitle}>
              {t('WIN.TEXT1')} <b>{gift?.name}</b>. {t('WIN.TEXT2')} -{'>'}{' '}
              {t('WIN.TEXT3')}
            </div>
            <Button type={'primary'} onClick={onClose} style={{width: '100%'}}>
              {t('WIN.TEXT4')}
            </Button>
          </div>
        </>
      )}
    </Modal>
  );
};
