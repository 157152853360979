import api from 'src/api/index';

export enum UserStatuses {
  New = 'NEW',
  Personified = 'PERSONIFIED',
  Approved = 'APPROVED',
}

export enum UserRoles {
  Partner = 'PARTNER',
  Admin = 'ADMIN',
  Author = 'AUTHOR',
}

export interface UserModel {
  id: string;
  email: string;
  createdAt: string;
  status: UserStatuses;
  name: string;
  phone?: string;
  telegram?: string;
  role: UserRoles;
  attempt_balance: number;
}
export const getUserInfoApi = (userID='') => {
  return api.get<UserModel>(`partner/users/${userID}`);
}

export const telegramCallbackApi = (payload: TelegramUserModel) => {
  return api.post<UserModel>('partner/users/messengers/telegram', payload);
}

export const openLootboxApi = () => api.get('partner/lootbox/lootbox_open');

export interface TelegramUserModel {
  auth_date: number;
  first_name: string;
  hash: string;
  id: number;
  last_name: string;
  username: string;
  photo_url?: string;
}
